import { Entity } from "./Entity";
/**
 * Presets for common UI elements.
 */
export class InterfaceColors extends Entity {}
Object.defineProperty(InterfaceColors, "className", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: "InterfaceColors"
});
Object.defineProperty(InterfaceColors, "classNames", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: Entity.classNames.concat([InterfaceColors.className])
});
